<template>
  <div>
    <el-form ref="form" :model="form" label-width="100px" :inline="true">
      <el-form-item label="设备编号：" >
        <el-input v-model="form.bicycleNo" clearable ></el-input>
      </el-form-item>
      <el-form-item label="用户手机号：">
        <el-input v-model="form.belongUser" clearable></el-input>
      </el-form-item>
      <el-form-item label="选择代理/门店：" label-width="180">
        <AgentTree
          :props="props"
          v-model="unitNos"
          placeholder="请选择分账主体"
          style="width: 300px"
          :selectKey='selectKey'
        ></AgentTree>
      </el-form-item>
      <el-button class="ml24" type="primary" @click="bicycleList">搜索</el-button>
    </el-form>
    <div class="df btnBox mb20">
      <el-button class="mr24 bkcyan bdcyan fff" icon="el-icon-success" @click="newAddVehicle('add')">新增电动车</el-button>
      <el-button class="mr24 bg-gray" icon="el-icon-success" @click="distributeHandle">分配设备</el-button>
      <el-button class="mr24" type="danger" icon="el-icon-success" @click="bicycleDelete">删除设备</el-button>
      <el-button class="mr24 bkyellow bdyellow fff" icon="el-icon-success" @click="editBtn">修改型号</el-button>
    </div>
    <el-table
       class="mb20"
      :data="tableData"
      @selection-change="handleSelectionChange"
      style="width: 100%">
      <el-table-column type="selection"></el-table-column>
      <el-table-column prop="bicycleNo" label="设备编号" width="180"/>
      <el-table-column prop="model" label="产品型号" />
      <el-table-column prop="udid" label="中控号" />
      <el-table-column prop="frameNo" label="车牌号" />
      <el-table-column prop="vinNo" label="车架号" />
      <el-table-column prop="belongUserName" label="归属用户" />
      <el-table-column prop="phone" label="归属用户手机号" />
      <el-table-column prop="agentName" label="代理商" />
      <el-table-column prop="storeName" label="门店" width="180"/>
      <el-table-column prop="createTime" label="导入时间" width="180"/>
      <el-table-column label="操作" width="180">
        <template slot-scope="{row}">
          <!-- <el-button size="mini" type="primary">详情</el-button> -->
          <el-button class="editBtn" size="mini" type="primary" @click="newAddVehicle('edit',row.bicycleNo)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="fe df">
      <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="beginIndex"
              :page-size="pageSize"
              @size-change="handleSizeChange"
              :page-sizes="[10, 20, 30, 50, 100, 150, 200]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
      </el-pagination>
    </div>

    <!-- <el-pagination
      @current-change="currentChange"
      layout="prev, pager, next ,total"
      :total="total">
    </el-pagination> -->
    <el-dialog title="修改型号" :visible.sync="dialogVisible" width="30%">
      <el-form>
        <el-form-item label="产品型号">
          <el-select
            v-model="productNo"
            placeholder="请选择产品型号"
            clearable
            filterable
          >
            <el-option
              v-for="opt in productList"
              :key="opt.productNo"
              :value="opt.productNo"
              :label="opt.productModel"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveProduct">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分配弹窗 -->
    <el-dialog title="分配设备" :visible.sync="distributionVisible" width="30%">
      <el-form>
        <el-form-item label="选择分配对象">
          <AgentTree
            :props="props"
            v-model="unitNos2"
            placeholder="请选择代理商/门店"
            style="width: 300px"
            :selectKey='selectKey'
          ></AgentTree>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="distributionBtn">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>

import { getProductList } from "@/api/product";
import  AgentTree from '@/components/AgentTree';
import {bicycleList,bicycleDistribute,bicycleDelete,editChangeProduct} from "@/api/facility"
export default {
  data() {
    return {
      unitNos2:[],
      distributionVisible:false,
      productNo:'',
      productList: [],
      dialogVisible:false,
      unitNos:[],
      multipleSelection: [],
      props: {
        value: "unitNo",
        label: "name",
        checkStrictly: true
      },
      selectKey: '',
      total:1,
      form:{

        bicycleNo:null,
        belongUser:null,
        unitNo:null
      },
      beginIndex:1,
      pageSize:20,
      tableData:[],
    }
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted(){
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.bicycleList()
      }
    };
    this.bicycleList();
    this.getProductList();
  },
   beforeRouteEnter (to, from, next) {
    if (from.path === '/leaseholdMgmt/addNewVehicle') {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false
    }
    next()
  },
  activated () {
    if (this.$route.meta.isBack) {
      this.$route.meta.isBack = false // 重置详情页标识isBack
    }
    // this.bicycleList();
    this.onSearch();
      this.getProductList();
  },
  methods: {
    onSearch() {
      this.beginIndex = 1;
      this.bicycleList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.onSearch();
    },
    /** 分页 */
    handleCurrentChange(page) {
      this.beginIndex = page;
      this.bicycleList();
    },
    // 分配按钮
    distributeHandle(){
      if(!this.multipleSelection.length){
        this.$message.warning('请选择设备');
        return;
      }
      this.distributionVisible = true;
    },
    // 分配确认按钮
    distributionBtn(){
      if(!this.unitNos2 || this.unitNos2.length === 0){
        this.$message.warning('请选择门店');
        return;
      }
      this.bicycleDistribute();
      this.distributionVisible = false;
    },
     //分配设备
    async bicycleDistribute(){
      const params = {
          unitNo:null,
          bicycleNos:this.multipleSelection//勾选电动车编号
        }
      if(this.unitNos2 && this.unitNos2.length !== 0){
        params.unitNo = this.unitNos2[this.unitNos2.length - 1];
      }
       try{
         const res = await bicycleDistribute(params);
        this.$message.success('分配成功');
        this.bicycleList();
       }catch(e){
         console.log(e,'e');
       }
    },
    // 修改型号确定按钮
    saveProduct(){
      if(!this.productNo){
         this.$message.warning('请选择产品型号');
         return;
      }
      this.editChangeProduct();
      this.dialogVisible = false;
    },
     /** 获取产品 */
    async getProductList() {
      const params = {
        ...this.form,
        productType: 2
      };
      const res = await getProductList(params);
      this.productList = res.ldata;
    },
    // 修改型号按钮
    editBtn(){
      if(this.multipleSelection.length === 0){
        this.$message.warning('请选择设备');
        return;
      }
      this.dialogVisible = true;
      this.productNo = '';

    },
    // 修改型号请求
    async editChangeProduct(){
      try{
        const params = {
          bicycleNos:this.multipleSelection,
          productNo:this.productNo
        };
        const res = await editChangeProduct(params);
        this.$message.success('修改成功');

      }catch(e){
        console.log(e,'e');
      }
    },
    // 删除按钮
    async bicycleDelete(){
      if(!this.multipleSelection.length){
        this.$message.warning('请选择设备');
        return;
      }
      this.$confirm('是否删除','提示',{
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(async ()=>{
        try{
          const params = {
            bicycleNos:this.multipleSelection
          }
          const res = await bicycleDelete(params);
          this.$message.success('删除成功');
          this.bicycleList();
        }catch(e){
          console.log(e,'e');
        }
      })

    },

    //新增电动车
    newAddVehicle(val,bicycleNo){
      this.$store.dispatch('login/checkHandle', '/managementPlatform/device/bicycle/addOrUpdate' ).then((flag) => {
        if(flag) {
           if(val === 'add'){
            this.$router.push({
              path:'/leaseholdMgmt/addNewVehicle',
              query:{
                status:'add'
              }
            });
          }else{//修改
            this.$router.push({
              path:'/leaseholdMgmt/addNewVehicle',
              query:{
                status:'edit',
                bicycleNo:bicycleNo
              }
            })
          }
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
        if(val === 'add'){
          this.$router.push({
            path:'/leaseholdMgmt/addNewVehicle',
            query:{
              status:'add'
            }
          });
        }else{//修改
          this.$router.push({
            path:'/leaseholdMgmt/addNewVehicle',
            query:{
              status:'edit',
              bicycleNo:bicycleNo
            }
          })
        }
      })

    },
    //多选
    handleSelectionChange(arr){
      const mulArr = arr.map(el => {
        return el.bicycleNo;
      });

      this.multipleSelection = mulArr;

    },
    // // 分页
    // currentChange(index){
    //   this.form.beginIndex = index - 1;
    //   this.bicycleList();
    // },
    //电车列表
    async bicycleList(){
       let page = this.beginIndex;
      page = page - 1;
      if(this.unitNos && this.unitNos.length !== 0){
        this.form.unitNo = this.unitNos[this.unitNos.length - 1]
      }else{
        this.form.unitNo = null;
      }
      try{
        const params = {
          ...this.form,
          pageSize: this.pageSize,
          beginIndex: page
        };
        const res = await bicycleList(params);
        this.tableData = res.ldata;
        this.total = res.mdata.total;
        // this.form = {
        //   bicycleNo:null,
        //   belongUser:null,
        //   unitNo:null
        // }
        // this.unitNos = [];
      }catch(e){
        console.log(e,'e');
      }
    },
  },
  components: {
    AgentTree,
  }
}
</script>

<style scoped>
.productModel{
  display:flex;
  width:50%;
}
  .btnBox{
    justify-content:flex-end;
  }
  .editBtn{
    background:rgb(255, 248, 241);
    border:1px solid orange;
    color:orange;
    font-weight:600;
  }
  .fc-fff{
    color:#fff;
    font-weight:600;
  }
  .bg-green{
    background:rgb(10, 211, 218);
  }
  .bg-yellow{
    background:rgb(236, 176, 47);
  }
  .bg-red{
    background:rgb(218, 10, 90);
  }
  .bg-gray{
    background:#eee;
  }
</style>
